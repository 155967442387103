@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: Roboto;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background: #f6f5fa;
}
button,
a {
  cursor: pointer;
}
a {
  text-decoration: none;
}

